@media screen and (min-width: 1025px){
    .md-tab {
        max-width: 190px !important;
    }
}

.innerContainer {
    justify-content: space-between;
    display: flex;
    padding: 10px;
    box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
    width: 90%;
    height: calc(100vh - 200px);
    flex-direction: column;
    background: #FFFFFF;
}

.tagList {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);
    padding: 6px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    float: left;
    color: #3f51b5;
    background: #e9e8f7;
}
.pSituationText{
    white-space: nowrap; 
    max-width: 200px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.md-table-pagination__label{
    display:none;
    height:0;
}
.md-select-field--btn{
    display:none;
    height:0;
}
.md-table-column, .md-table-column--adjusted {
    padding-right: 0px
}
.md-tab--active {
    background: #303f92;
}  
.md-layover--simplified{
    width: 93%;
}
.md-list--menu-restricted {
    max-height: 264px;
    margin: 20px;
}
.md-dialog--centered {
    left: 50%;
    top: 34%;
    min-width: 470px;
}
.md-dialog {
    width: 356px;
    cursor: auto;
    position: fixed;
    background: #FFFFFF;
}
.md-text-field {
    font-size: 14px;
}

.md-grid {
    padding: 0px !important; 
}
.ContentContainer {
   display:flex;
   justify-content: flex-start;
   flex-direction: row;
   height: calc(100vh - 160px);
   width: 100%;
   .categoryList {
       width: 120px;
       height: 100%;
       box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2);
       .category {
           height: 90px;
           display: flex;
           flex-direction: column;
           justify-content: center;
           padding: 10px;
           text-align: center;
           color: #989898;
           font-size: 14px;
           align-items: center;
           text-decoration: none;
           cursor: pointer;
           .md-icon {
               font-size: 24px;
           }
   
           &:hover {
               background-color: #e9e8f7;
               color:#3f51b5 !important;
               .md-icon{
                       color:#3f51b5 !important;
               }
           }
               
       }
       .active {
           background-color: #e9e8f7;
           color:#3f51b5 !important;
           .md-icon{
                   color:#3f51b5 !important;
           }
       }
   }
}
.risklevel_1 {
    .md-text--inherit {
        color: #d31f37;
    }
}


.risklevel_2 {
    .md-text--inherit {
        color:#f58823;
    }
}

.risklevel_3 {
    .md-text--inherit {
        color: #ffdb1a;
    }
}

.risklevel_4 {
    .md-text--inherit {
        color: #26b954;
    }
}

.risklevel_4 {
    .md-switch-thumb--on{
        background: #26b954 !important;
    }
    .md-switch-track--on {
        background: rgba(38, 185, 84, 0.51);
    }
}

.risklevel_3 {
    .md-switch-thumb--on{
        background: #ffdb1a !important;
    }
    .md-switch-track--on {
        background: rgba(255,221,26,.19);
    }
}
.risklevel_2 {
    .md-switch-thumb--on{
        background: #f58823 !important;
    }
    .md-switch-track--on {
        background: rgba(245,137,36,.18)
    }
}
.risklevel_1{
    .md-switch-thumb--on{
        background: #d31f37 !important;
    }
    .md-switch-track--on {
        background: rgba(209,31,55,.2);
    }
}
.risklevel_5 {
    .md-switch-thumb--on {
        background: #4a90e2 !important;
    }
    .md-switch-track--on {
        background: rgba(75,145,226,.2);
    }
}
.md-floating-label--inactive-sized {
   font-size:14px;
}
.tags {
   box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 1px 5px 0 rgba(0,0,0,0.12), 0 3px 1px -2px rgba(0,0,0,0.2);
   padding: 6px;
   margin: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: auto;
   float: left;
   color: rgb(63, 81, 181);
   background: #e9e8f7;

   &:hover {
       background: #d4d2f7;
   }

   .material-icons {
       cursor: pointer;
       margin-left: 5px;
       font-size: 20px;
       margin-left: 10px;
   }
}
.questionContainer {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 10px;
}
.qSelectFeild {
    margin: 0px !important;
    margin-top: -15px !important;
    width: 83.33333%;
}
.qCategory {
    margin: 5px 0px;
    border: 1px solid #a9a9a9;
    padding: 20px;
    width: 98%;
}
.qBtns {
    display: flex;
    justify-content: flex-start;
}
.editQuizTitle {
    display: flex;
    padding: 5px;
    align-items: center;
    justify-content: center;
    input {
        padding: 10px;
        height: 30px;
    }
}
.qRows {
    color:#737373;
    display: flex;
    align-items: center;
    margin-top: 10px;

    .qRowsLeft {
        display: flex;
        justify-content: space-between;
        width: 100px;
    }
    .md-icon-text:last-child {
        padding: 0px;
    }
    .md-cell {
        margin: 0px 8px;
        display: flex;
    }
    button {
        opacity: .2;
        margin: 0px 10px;
        // display: none;
    }
    &:hover {
        button {
            opacity: 1;
            visibility: visible;
        }
    }    
}
