.alertBox {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    padding-top: 100px; 
    z-index: 10000;
    background: rgba(0, 0, 0, 0.39);
    top: 0;
    left: 0;
}