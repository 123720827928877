.quiz-progressBar {
  background-color: #f1f1f1!important;
  width: 50%;
  height: 10px;
  .not_attended {

  }
  .not_finished {
    background-color: #4a90e2;
    width: 60%;
    height: 10px;
  }
  .passed {
    background-color: #26b954;
    width: 100%;
    height: 10px;
  }
}
