.section {                
    display: flex;
    width: 100%;
    min-height: 150px;
    margin: 1px;
        &-head{
            border: 3px solid #CCCCCC;
            font-weight: 600;
            margin-right: 10px;
            width: 40px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            h2 {
                transform: rotate(-90deg);
                position: absolute;
            }
        }

        &-body {
            width: 100%;
        }
        .declaration {
            display: flex;
            flex-direction: row;
            font-size: 12px;
            margin-top: 10px;
            &_1{
                width: 15%;
            }
            &_2{
                width: 15%;
            }
            &_3{
                width: 35%;
            }
        }
    }       

    .grid-example {
        padding: 0px;
        width: 100%;
        display: flex;
    }
    .cell {
        border: 1px solid rgb(184, 184, 184);
        margin: 0px;
        padding: 0px 5px;
        float: left;
        font-size: 12px;
        min-height: 30px;
        align-items: flex-start;
        display: flex;
        background-color: #FFFFFF;            
        flex-direction: column;
    }
    .size {
        &1 {
            width: 6.33%;
            align-items: center;
            display: flex;
            justify-content: center;
            color: #a0a0a0;
            font-weight: 600;
            font-size: 14px;
        }
        &2 {
            width: 23.666%;
        }
        &3 {
            width: 27%;
            max-width: 200px;
            justify-content: center;
        }
    }
    
    .cell-size-1 {
        width: 11%;
    }
    .cell-size-2 {
        width: 16%;
    }
    .cell-size-2-by-2 {
        width: 8%;
    }

    .cell-size-17 {
        width: 17.33%;
    } 
    .side-riskLevel1-border{
        border-left: 3px solid #d31f37;
        border-right: 3px solid #d31f37;
    }       
    .top-riskLevel1-border{
        border-top: 3px solid #d31f37;
    }     
    .bottom-riskLevel1-border{
            border-bottom: 2px solid #d31f37;
    }

    .side-riskLevel2-border{
        border-left: 3px solid #f58823;
        border-right: 3px solid #f58823;
    }       
    .top-riskLevel2-border{
        border-top: 3px solid #f58823;
    }     
    .bottom-riskLevel2-border{
        border-bottom: 2px solid #f58823;
    }        
    
    .side-riskLevel3-border{
        border-left: 3px solid #ffdb1a;
        border-right: 3px solid #ffdb1a;
    }       
    .top-riskLevel3-border {
        border-top: 3px solid #ffdb1a;
    }     
    .bottom-riskLevel3-border {
        border-bottom: 2px solid #ffdb1a;
    }

    .side-riskLevel4-border{
        border-left: 3px solid #26b954;
        border-right: 3px solid #26b954;
    }       
    .top-riskLevel4-border{
        border-top: 3px solid #26b954;
    }     
    .bottom-riskLevel4-border{
        border-bottom: 2px solid #26b954;
    }
    .side-riskLevel5-border{
        border-left: 3px solid #4a90e2;
        border-right: 3px solid #4a90e2;
    }       
    .top-riskLevel5-border{
        border-top: 3px solid #4a90e2;
    }     
    .bottom-riskLevel5-border{
        border-bottom: 2px solid #4a90e2;
    }
    .check-box{
        display: flex;
        justify-content: left;
        align-items: center;
        .box{
            height: 13px;
            width: 13px;
            border: 1px solid #303030;
            .material-icons.md-icon {
                font-size: 22px;
                color: #303030;
                top: -8px;
                left: -2px;
                position: relative;
            }
        }
        .text {
            padding-left: 7px;
        }
    }
    .personalData {
        display: inline-block;
        .box_value {
            clear: both;
            color: #0000009c !important;
            width: 100%;
        }
        .table-content {
            color: #000000 !important;
            float: left;
            width: calc(100% - 400px);
            height: 100px;
            font-size: 12px;
            border: 1px solid #CCCCCC;
            .table-row {
                height: 50%;
                width: 100%;
                .box {
                    &_small {
                        width : calc( 100% / 7);
                        float: left;
                        font-size:12px;
                        height: 100%;
                        border: 1px solid #CCCCCC;
                        padding: 5px;
                    }
                    &_medium {
                        width: 17%;
                        float: left;
                        height: 100%;
                        font-size:12px;
                        border: 1px solid #CCCCCC;
                        padding: 5px;
                    }
                    &_large {
                        width: 23%;
                        float: left;
                        height: 100%;
                        font-size:12px;
                        border: 1px solid #CCCCCC;
                        padding: 5px;
                    }
                    &_x-large {
                        width: 43%;
                        float: left;
                        font-size:12px;
                        height: 100%;
                        border: 1px solid #CCCCCC;
                        padding: 5px;
                    }
                }
            }
        }
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-right: -15px;
            margin-left: -15px;
            float: right;
            min-width: 375px;
            padding: 1px;
        }
        .col-sm-4 {
            flex: 0 0 30%;
            max-width: 30%;
        }
        .col-sm-6 {
            flex: 0 0 70%;
            max-width: 70%;
        }
    }

    .printEnteredValue {
        font-size: 1rem;
        text-align: center;
        width: 100%;
        margin: auto;
        padding: 10px;

        &-risk1{
            color:#d31f37 !important;
        }

        &-risk2{
            color:#f58823 !important;
        }

        &-risk3{
            color:#ffdb1a !important;
        }

        &-risk4{
            color:#26b954 !important;
        }
        
        &-risk5{
            color:#4a90e2 !important;
        }
    }
    .amemnesis-container{
        display: inline-block;
        clear: both;
        text-align: left;
        margin-top: 0px;
        .disease-list {
            margin: 3px 5px;
            min-width: 165px;
            float: left;      
            .md-selection-control-container {
                height: 30px;
                float: left;
                clear: both;
            }
            .subList {
                float: left;
                width: auto;
                display: flex;
            }
        }
    }
    .warning-attention {
        border: 1px solid #cccccc;
        margin-bottom: -4px;
        width: 100%;
        display: inline-block;
        font-size:12px;
        .disease-list {
            width: auto;
            min-width: 80px;
            margin-bottom: 0px;    
            .md-selection-control-container {
                height: 30px;
                float: left;
                clear: both;
                .md-btn--icon {
                    border-radius: 50%;
                    color: rgba(0, 0, 0, 0.54);
                    height: 24px;
                    padding: 4px;
                    width: 32px;
                }
            }
            .subList {
                width: 195px;
                display: inline-block;
                font-size:12px;
                margin-top: 10px !important;
                .check-box {
                    margin-right: 10px;
                    float: left;
                    width:85px;
                }
            }
            .md-subheading-1 {
                color: #d31f37 !important;
                margin-bottom: 0px !important;
                font-size: 12px;
            }
            .md-selection-control-label {
                font-size: 12px !important;
                color: #222222
            }
        }
    }

    .process-box-container {
        margin: 10px 10px 0px 5px;
        display: flex;
        flex-direction: row;
        .process-box {
            width: 22px;
            height: 22px;
            margin-right: 5px;
        }
        .process-text-field {
            border-bottom: 1px solid rgb(184, 184, 184);
            width: calc(100% - 105px)
        }
    }
    .declaration_top {
        margin: 10px 0px;
        font-size: 14px;
        width: 100%;
        clear: both;
        display: inline-block;
        &_left {
            float:left;
        }
        &_right {
            float:right;
            width: 345px;
            span {
                display: inline-block;
            }
            .disipline {                    
                width: 60%;
            }
        }
        
        .medical_triage {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            .checkbox {
                align-self: flex-end;
                width: 100px;
                margin: 0px 10px;
            }
        }
    }
    .free-text {
        .textbox {
            display: flex;
            clear: both;
            width: 100%;
        }
        .patient_desc {
            display: flex;
            padding: 12px 0px 0px;
            clear: both;
            width: 100%;
            border-bottom: 1px solid #b8b8b8;
            line-height: 2;
            color: #424242;
            .patient_desc_prefix {
                color: #222222;
                padding-right: 5px;
            }
        }
    }