@-moz-document url-prefix() {
    @media print {
        .bgImage {
            top: 0;
            margin: 0;
            display: block;
            width: 208mm;
            height: 270mm;
        }
    }
}


