.ManageQuestionsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .footer {
        height: auto;
        padding: 10px 20px;
        width: calc(100% + 20px);
        color: #737373;
        font-size: 20px;
        background: #e9e8f7;
        margin: 0px -10px -10px -10px;
        display: flex;
        .md-btn{
            min-width: 80px;
            padding: 10px;
        }
    }

    .editPanel{
        position: absolute;
        width: 100%;
        z-index: 1000;
        /* background-color: #000000cc; */
        top: 0;
        bottom: 0;
        left: 0%;
        .AddQuestionsContainer{
            background-color: rgba(5, 5, 5, 0.6392156862745098);
        }
    }
}