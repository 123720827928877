.headerContainer{
    background: #45759a; // #a9ced0;
    color: #fff;
    display:flex;
    margin: 0px;
    padding: 10px 40px 0;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height:auto;
    min-height: 60px;
    .MenuContainer{
        display: flex;
        flex-direction: row;
        padding: 0px;
        margin: 0px;
        list-style-type: none;
        li {
            padding: 0px 10px;
            min-width: 45px;
            cursor: pointer;
            display: flex;
            align-items: center;
            color: #fff; //#444444;
            div {
                display: flex;
                color: #fff; //#444444;
                padding: 5px;
                border: none;
            }
            .md-icon {
                color: #fff; //#444444;
            }
            .md-icon-text {
                color: #fff; //#444444;
            }
            .margin-right-min25 {
                margin-right: -23px!important;
            }
        }
    }
}
