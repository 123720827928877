.AdminListContainer {
    display: flex;
    width: 100%;
    height: calc(100vh - 140px);
    justify-content: space-between;
    padding: 8px;
    .md-data-table--responsive {
      width:100%;
    }

    /* width */
// ::-webkit-scrollbar {
//     width:8px;
//   }

//   /* Track */
//   ::-webkit-scrollbar-track {
//     box-shadow: inset 0 0 5px grey;
//     border-radius: 10px;
//   }

//   /* Handle */
//   ::-webkit-scrollbar-thumb {
//     background: #f50057;
//     border-radius: 10px;
//   }

//   /* Handle on hover */
//   ::-webkit-scrollbar-thumb:hover {
//     background: #f50057;
//   }
 }

.DialogContainer {
  display: flex;
  position: fixed;
  top: 0;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,.3);

  .textField {
      margin: 0px 30px;
  }
}

#admin-list {
  .categoryHeading {
    margin-top: 30px;
    color: #737373;
    text-align: center;
  }
}

