.BreadCrums {
    margin: 0px 0px 10px;
    display: flex;
}

.wrapper-progressBar {
    width: 100%
}
.progressBar {
    .risklevel_1:before {
        border-color: #d31f37 !important;
        background-color: #d31f37 !important;
    }
    .risklevel_2:before {
        border-color: #f58823 !important;
        background-color: #f58823 !important;
    }
    .risklevel_3:before {
        border-color: #ffdb1a !important;
        background-color: #ffdb1a !important;
    }
    .risklevel_4:before {
        border-color: #26b954 !important;
        background-color: #26b954 !important;
    }
}
.progressBar li {
    list-style-type: none;
    float: left;
    width: 25%;
    position: relative;
    text-align: center;

    span {
        position: absolute;
        top: 10px;
        text-align: center;
        width: 100%;
        left: 0;
        color: white;
        z-index: 10;
    }
}

.progressBar li:before {
    content: " ";
    line-height: 30px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border: 1px solid #ddd;
    display: block;
    text-align: center;
    margin: 0 auto 10px;
    background-color: #dddddd;
    z-index: 9;
    position: absolute;
    left: calc(50% - 15px);
    top: 3px;
}

.progressBar li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #858585;
    top: 15px;
    left: -47%;
}

.progressBar li:first-child:after {
    content: none;
}

.progressBar li.active {
    color: #858585;
}

.progressBar li.active:before {
    border-color: #858585;
    background-color: #858585
}

.progressBar .active:after {
    background-color: #858585;
}

.breadCrum_name {
    padding-top: 35px;
    font-size: 20px;
    color: #000;
}