.md-tabs-content {
    height: calc(100% - 120px);
    overflow: auto;
    margin-bottom: 20px;
}
.addCourseContainer {
    display: flex;
    width: calc(100% - 30px);
    align-items: center;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
    margin: 14px;
    justify-content: space-evenly;
    flex-direction: column;
    padding: 30px 10px;
    height: calc(100vh - 190px);
    li{
        min-height: auto;
    }

    .md-text--secondary{
        text-align: left;
        width: 100%;
    }
    .md-text--theme-primary{
        text-align: left;
        width: 100%;
    }
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 450px;
        width:100%;
        label {
            width: 100%;
            padding-left: 10px;
        }
        .md-btn{
            width: auto;
        }
        .md-cell {
            margin :4px;
            align-items: center;
            justify-content: center;
        }
        .md-text-field--margin {
            margin-top: 5px;
        }
    }
    .selectBtn {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .md-icon-text{
            color: #ffffff !important;
            font-style: normal;
        }
        span {
            font-size: 12px;
            margin: 3px 0px;
            color: #444444;
            font-style: italic;
        }
    }
    button {
        min-width: 125px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .md_icon {
            color : #ffffff;
        }
    }
    .msg {
        padding: 2px 13px;
        font-size: 12px;
        background: rgba(245, 0, 87, 0.19);
        display: flex;
        align-items: flex-end;
        overflow-wrap: break-word;
        justify-content: center;
        margin: 10px;
    }
    .error {
        color: #dd1158;
    }
    .file_detail {
        border: 1px solid rgba(153, 153, 153, 0.67);
        padding: 2px 13px;
        font-size: 12px;
        background: rgba(197, 188, 191, 0.19);
        display: flex;
        align-items: flex-end;
        overflow-wrap: break-word;
        justify-content: space-between;
        align-items: center;
        align-self: baseline;
        width: auto;
        margin-bottom: 15px !important;
        .md-icon {
            font-size: 18px;
            padding-left: 10px;
            cursor: pointer;

        }
    }

}

.editOverlay{
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: rgba(0, 0, 0, 0.57);
    color: #fff;
    font-size: 18px;
}

.tab-panel {
    display: flex;
    justify-content: center;
}

.resourceContainer {
    // Tooltips causes scrollbars to be created, which is why we hide them
    overflow-x: hidden;
    height: auto;
    max-height: calc(100vh - 185px);
    width: calc(100% - 30px);
    align-items: flex-start;
    max-width: 1100px;
    margin: 14px;
    display: flex;
}

.resourceGroup {
    padding: 7px;
}

.courseContainer {
    width: calc(100% - 30px);
    min-height: 250px;
    align-items: center;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 3px 5px -1px rgba(0, 0, 0, 0.4);
    margin: 14px;
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    height: auto;
    overflow: auto;
    margin-bottom: 20px;
    max-height: calc(100vh - 185px);
    .addCourseContainer {
        box-shadow: none;
        padding: 0;
        height: auto !important;
        li{
            min-height: auto;
        }
    }
    .grid-example {
        max-width: 1200px;
    }
    .placeholder {
        background: rgb(255,240,120);
        display: flex;
        align-items: center;
        justify-content: center;
        &:before {
          content: "Drop here";
          color: rgb(225,210,90);
        }
    }

    li {
        list-style-type: none;
        padding: 5px;
        min-height: 220px;
    }
    @media (max-width: 599px) {
        .md-cell--3, .md-cell--3-phone.md-cell--3-phone {
            width: calc(100% - 16px);
        }
    }

    .md-cell--12, .md-cell--12-desktop.md-cell--12-desktop {
        width: calc(100% - 40px);
    }

    .courseBox {
        height: 30vh;;
        cursor: pointer;
        background-size: cover;
        &:hover {
            .courseTitle {
                box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3), 0 4px 20px 0 rgba(0,0,0,0.39);
                background: rgba(0, 0, 0, 0.7);
            }
        }
    }
    .courseTitle {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        justify-content: center;
        text-transform: capitalize;
        color: #ffffff;
        text-shadow: 2px 2px #000000;
        background: rgba(0, 0, 0, 0.4);
        font-size: 20px;
        padding: 10px;
        text-align: center;
    }
    .editCourse {
        height: 30vh;
        background: #e8e8e8 !important;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        box-shadow: 0 4px 10px 0 rgba(0,0,0,0.3), 0 4px 20px 0 rgba(0,0,0,0.39);

        .md-text-field {
            padding: 5px !important;
        }
        .md-cell {
            margin: 3px;
        }
        .md-text-field--margin {
            margin-top: 5px;
        }
        #title {
            color: #607d8b;
        }
        .selectBtn {
            .msg {
                font-size: 10px;
            }
        }
        .file_detail {
            background: #607d8b;
            font-size: 12px;
            color: #fff;
            width: auto;
            padding: 5px;
            align-items: center;
            display: flex;
            max-width: 90%;
            .md-icon {
                font-size: 18px;
                padding: 0px 5px;
            }
        }
        .error {
            color: red;
            font-size: 12px;
            width: 90%;
        }
    }
    .progressBar {
        background: #bdbdbd;
        width: 100%;
        height:10px;
        z-index: 10;
        margin-top: -10px;
    }
    .progressBarActive {
        background: #0d7409;
        height: 10px;
    }
    .sideBar {
        position: relative;
        float: right;
        display: flex;
        background: rgba(255, 255, 255, 0.8);
        margin-top: -30vh;
        flex-direction: column;
        justify-content: space-between;
        padding: 5px;
        cursor: auto;
        height: 100%;
        .md-icon {
            font-size: 17px;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 839px) {
    .grid-example{
        justify-content: space-evenly;
    }
}














.example {
    border-left: 1px solid #999999;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-top: 1px solid #999999;
    border-radius: 10px;
    margin: 0px;
    padding: 20px;
  }

  .item {
    font-family: sans-serif;
    border-top: 1px solid #ffffff;
    background-color: #eeeeee;
    border-bottom: 1px solid #999999;
    border-radius: 6px;
    margin-bottom: 4px;
    padding: 10px;
    padding-left: 8px;
    opacity: 1;
  }

  .item .title {
    font-weight: bold;
    font-size: 125%;
    margin: 0;
    margin-bottom: 0.3rem;
  }

  .item .body {
    margin: 0;
    margin-bottom: 0.3rem;
  }

  .item .small {
    font-size: 66%;
    text-align: right;
  }

  .dragged .item {
    opacity: 0.25;
    border-top: 1px solid #999999;
    border-bottom: 1px solid #ffffff;
  }

  .floating .item {
    background-color: #ffffff;
    box-shadow: 0 4px 20px #666666;
  }

  .dl-item.floating {
    width: 85%;
  }
