
.QuizContainerWithbreadCrum{
    flex-direction: column;
    justify-content: space-around !important;
    align-items: inherit !important;
    .quizHeader {
        display: flex;
    }
    .wrapper_container{
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        margin: auto
    }
    .result_box{
        display: flex;
        flex-direction: row;
        justify-content: center;
        p {
            line-height: 0;
            font-size: 30px;
            font-weight: 600;
            height: 31px;
        }
        .md-selection-control-group {
            display: contents;
        }
        .md-icon{
            font-weight: 900;
            font-size: 30px;
        }
    }
    .finalPri_outerContainer{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 50%;
    }
    .finalPrio_container {
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        margin: auto;
        background: #2a5c65;
        opacity: .85;
        border:1px solid;
        padding: 15px;
        border-radius: 10px;
    }
    .finalPrio_wrapper {
        display: flex;
        padding: 10px 25px;
        flex-direction: column;
        justify-content: flex-end;
        align-self: center;
        width: 50%;
        align-items: center;
        p {
            font-size: 30px;
            font-weight: 600
        }
    }
    .facts {
        width: 40%;
        background: #2a5c65;
        opacity: .85;
        border:1px solid;
        padding: 10px;
        border-radius: 10px;
        float: left;
        margin: 10px 5%;
        height: auto;
        color: #fff;
    }
    .footer {
        width: 50% !important;
        align-self: center !important;
        min-width: 320px !important;
    }
    .essTitle {
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
    }
    .textAboveTickBox{
        justify-content: center;
        display: flex;
        align-items: center;
        font-size: 20px;
        font-weight: bold;
    }
}
.QuizContainer {
    h2 {
        color: #fff;
    }
    width: 100%;
    height: calc(100vh - 115px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #737373;
    background-image: url('../../../../../Assets/green_bg.svg');
    background-size: cover;
    padding: 40px;
    overflow: auto;
    .wrapper {
        display: flex;
        padding: 10px 25px;
        flex-direction: column;
        justify-content: flex-end;
        align-self: center;
        width: 50%;
    }
    .innerContainer {
        border: 1px solid #102427;
        border-radius: 10px;
        background-color: #2a5c65;
        opacity: .85;
        color: #fff;
        height: auto;
        overflow-y: scroll;
        align-items: flex-start;
        width: 100%;
        max-height: 256px;
        display: block;
        .quizDescription {
            height: 100%;
            font-size: 14px;
            margin: 15px 10px;
        }
        .questionCount {
            font-size: 14px;
            margin: 5px 10px;
            display: flex;
            flex-direction: row;
        }
    }

    .footer {
        height: auto;
        padding: 10px 20px;
        width: calc(100% + 50px);
        color: #737373;
        font-size: 20px;
        display: flex;
        margin: 0px -25px -10px -25px;
        justify-content: space-between;

        button {
            border: 1px solid !important;
            border-radius: 5px !important;
            background: #cdcdcd !important;
            color: #4a494a !important;
        }
    }

}
