.footerContainer {
    display: inline-block;
    width: 100%;
    padding: 5px;
    text-align: center;
    position: fixed;
    bottom: 0;
    background: #45749a;
    a {
        color: #fff;
        text-decoration: none;
        cursor: 'pointer';
    }
}