@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) {
    @media print {
        .bgImage {
            top: 0;
            margin: 0;
            display: block;
            width: 208mm;
            height: 295mm;
        }
    }

    @page {
        size: A4 portrait;
        margin: 1mm 1mm 1mm 1mm;
    }
}

