@import '~react-md/src/scss/react-md';
body {
    margin:0px;
    overflow: hidden;
    text-rendering: optimizeLegibility;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}

.response_msg {
    color: #757575;
    padding: 10px;
    font-style: italic;
}
.capitalize {
    text-transform: capitalize;
}
.loaderLayout {
    z-index: 1000;
    background: #efeffb;
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .9;
    justify-content: center;
    align-items: center;
    img {
        height:100px;
    }
}
.language-dropdown {
    .md-select-field--btn.md-select-field--btn{
        color: #efeffb;
    }
    .md-icon{
        color: #efeffb;
    }
}
.md-background--primary {
    background: #45749a !important;
}
.text-center {
    text-align: center;
}
.md-text-field{
    border: 1px solid grey;
    min-height: 25px;
    font-size: inherit;
    padding: 12px;
    border-radius: 3px;
}
.md-divider{
    display: none;
}
.md-select-field--text-field{
    padding: 20px;
}
ul#select-field-6-menu-options {
    background: #607D8B;
    margin: 0px;
}

.md-tab--active{
  background-color: #45749a;
}

.md-tab-indicator {
  height: 4px !important;
}

#diplomaIframe {
    margin-top: 25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.diplomaContent {
    display: none;
}
