.AddQuizContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerContainer {
        justify-content: space-between;
        display: flex;
        padding: 10px 25px;
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
        width: 90%;
        height: calc(100vh - 200px);
        flex-direction: column;
        overflow-y: scroll;
        
        .categoryHeading {
            margin-top: 10px;
            color: #737373;
        }
        .md-text {
            color: #737373;
        }
        .md-selection-control-label {
            font-size: 14px;
        }
        .md-subheading-1 {
            color: #737373;
            font-size: 14px;
        }
        .container {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: space-between;
            .md-data-table .md-table-header .md-table-checkbox .md-selection-control-label{
                display: none;
            }
            .filtermodal{
                position: absolute;
                .md-dialog{
                    left: 270vw;
                }
            }
        }
        .filerContainer {
            min-height: 150px;
            height: calc(100% - 40px);
            overflow-y: scroll;
            .filterTitle{
                font-weight: bold;
            }
            .addFilterBtn {
                width: 100%;
                text-align: right;
                margin-bottom: 15px;
            }
            .filterRow {
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                color: #737373;
            }
        }
        .footer {
            height: auto;
            padding: 10px;
            width: calc(100% + 50px);
            color: #737373;
            font-size: 20px;
            background: #e9e8f7;
            margin: 0px -25px -10px -25px;
            display: flex;
            justify-content: flex-end;
            .pagination{
                width:100%;
                display: flex;
                justify-content: space-between;
                .md-data-table--responsive{
                    height: 0px;                    
                    margin-top: -12px;
                }
            }

            .md-btn {
                min-width: 80px;
                padding: 10px;
            }
        }
    }
}