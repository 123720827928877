.tagContainer{
    display:flex;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    margin-top: 50px;
    
    .DialogContainer{
        display: flex;
        position: fixed;
        left: 0;
        top: 0;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,.3);

        .textField {
            margin: 0px 30px;
        }
    }
    
    .tagLists{
        display:flex;
        flex-direction: column;
        justify-content: center;
        width: 80%;

        .tagListHeader {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .tagDialogBox {
            display: inline-block;
            padding: 10px;
            box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -5px rgba(0,0,0,0.4);
            
        }
    }

    h2 {
        color: rgb(115, 115, 115);
    }
}