.iframeContainer {
    text-align: center;
    height: 1000px;
    background-color: #fff;
}

.diplomaContent.isVisible {
    display: block;
}

.diplomaContent.hidden {
    display: none;
}
