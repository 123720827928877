.login {
    display: flex;
    justify-content: center;
    height: 100vh;
    width: 100%;
    background-color: #d2d2d2;
    form {
        margin-top: 40px;
    }
    .md-paper {
        margin-top: 80px;
        padding: 15px;
        text-align: center;
        height: fit-content;
        background-color: #fff;
        border: 3px solid;
        max-height: 320px;
        form {
            width: 320px;
        }
        .md-cell {
            width: auto !important;
            text-align: left;
        }
        .md-btn {
            margin: 20px 0px 0px;
        }
        .md-text-field{
            padding: 5px;
            border-radius: 3px;
            margin-top: -30px;
        }
    }
    .md-password-btn {
        display: none;
    }
    .md-text-field--inline-indicator {
        width: 100%;
    }
}
