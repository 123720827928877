.AddQuestionsContainer{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .innerContainer {
        justify-content: space-between;
        display: flex;
        padding: 10px;
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
        width: 90%;
        height: calc(100vh - 200px);
        flex-direction: column;
        .veriosnDialogBox {
            display: flex;
            align-items: center;
        }

        .autoWidth {
            width: auto;
        }

        .remainingWidth {
            width: 80%;
        }

        .categoryHeading {
            margin-top: 30px;
            color: #737373;
        }
        .md-selection-control-group {
            border: 0;
            display: contents;
        }
        .addIcon {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            display: none;
        }
        .essSearchBar {
            width: 100%;
            label {
                word-break: break-all;
                width: 100%;
                padding: 7px;
                font-size: 20px;
            }
        }
        .footer {
            height: auto;
            padding: 10px 20px;
            width: calc(100% + 20px);
            color: #737373;
            font-size: 20px;
            background: #e9e8f7;
            margin: 0px -10px -10px -10px;
            display: flex;
            justify-content: flex-end;
            .md-btn {
                min-width: 80px;
                padding: 10px;
            }
        }
        .questionContainer{
            .qCategory{
                .qRows{
                    .qBtns{
                        button{
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .divider{
        border-bottom: 1px solid #9e9e9e;
        padding: 5px 0px;
    }
    .title{
        font-size: 14px;
        font-weight: 600;
        margin:10px 0px;
    }
    .infos {
        padding: 10px;
        margin: 10px;
        border: 1px solid;
        border-radius: 5px;
    }
    .multipleAnswerOuterContainer {
        width: 100%;
    }
    .multipleAnswerContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
}

.tagListContainer {
    display: flex;
}

#essSearchBarTab {
    width: 100%;
    border:0px;
    border-bottom: 1px solid grey;
}
.md-layover--simplified {
    width: calc(100% - 16px) !important;
}