.OuterContainer {
    height: calc(100vh - 80px);
    width: 100%;
    .selectBox {
        padding: 10px 0px 0px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        #languageList-menu {
            width: 200px !important;
        }
    }
    .studentContainer {
        width: 100%;
        height: calc(100% - 115px);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        .md-table-body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .md-table-row {
            display: flex;
            margin: 5px;
            max-width: 600px;
            width: 100%;
            cursor: pointer;
            padding: 10px;
            border: 1px solid #cdcdcd;
            justify-content: space-between;
            align-items: center;
            box-shadow: 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12), 0 3px 1px -2px rgba(0,0,0,.2)
        }
        .md-table-column--plain {
            height: auto;
            color: #525252;
            h3 {
                margin-bottom: 5px;
                color: #525252;
            }
            .progressBar {
                background: #bdbdbd;
                width: 100px;
                height: 9px;
                z-index: 10;
                margin-top: -10px;
                border: 1px solid #979797;
            }
            .progressBarActive {
                height: 7px;
            }
        }
        .md-data-table--responsive {
            -webkit-overflow-scrolling: touch;
            overflow-x: auto;
            width: 100%;
        }
        .footer {
            height: auto;
            padding: 10px 20px;
            width: 100%;
            color: #737373;
            font-size: 20px;
            background: transparent;
            display: flex;            
            position: absolute;
            bottom: 0;
        }
    }
    .quizlangSelectBox{
        .md-select-field--btn{
            display: flex;
        }
    }


    .errorBox {
        background: #ffb43f;
        width: auto;
        height: auto;
        padding: 5px;
        color: #FF0000;
        align-self: center;
        box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.47), 0 8px 10px -5px rgba(0, 0, 0, 0.4);
        border-radius: 2px;
        font-size: 14px;
        align-items: center;
        justify-content: unset;
        border-radius: 5px;
        padding: 10px;
        display: flex;
        transition: opacity .2s, visibility .2s, margin-top 0s .2s;

        .md-icon {
            color: #FF0000;
        }
        span {
            margin-left: 8px;
        }
    }
}

.noResult{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 14px;
}

input#tagListContainer {
    border: 1px solid grey;
    margin: 0px;
}