@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .courseName {
        text-align: center;
        top: 61%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 48px;
    }

    .studentName {
        text-align: center;
        position: absolute;
        top: 38%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 48px;
        line-height: 1;
    }

    @media all {
        .page-break {
            display: none;
        }
    }

    @media print {
        .bgImage {
            width: 100%;
            height: 98%;
        }
    }

    @page {
        size: A4 portrait;
        margin: 5mm 1mm 1mm 1mm;
    }
}
