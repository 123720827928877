
.caseHeading {
    font-size: 20px;
    color: #565656;
    font-style: italic;
    width: 50%;
    float: left;
}
.stepContent {
    height:100%;
}
.finalFooterStep{
    justify-content: space-around !important;
    clear: both;
}
.stepsFooter{
    display: flex;
    justify-content: space-between;
    margin: 5px -30px;
    align-items: flex-end;
    button {
        border: 1px solid !important;
        border-radius: 5px !important;
        background: #cdcdcd !important;
        color: #4a494a !important;
    }
}
.essHeading {
    font-size: 16px;
    font-weight: 600;
    padding:10px;
}
.essContent {
    padding:10px;
}
.divider{
    border-bottom: 1px solid #9e9e9e;
    padding: 5px 0px;
}
.questionCategoryHeading {
    margin: 0px 0px 10px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
}
.questionSituation {
    line-height: 1.6rem;
    margin: 0px 0px 20px;
    font-size: 15px;
    color: #fff;
    height: 150px;
    overflow: auto;
}
.essSymptoms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    .correct {
        color: #26b954;
        font-size:26px;
    }
    .wrong {
        color: #d31f37;
        font-size:26px;
    }
    .md-text {
        color: #fff!important;
    }
}
.vitalSignsCategory {
    border: 1px solid #a2a2a2;
    background: #a1a1a1;
    padding:5px;
    float: left;
    color: white;
    border-radius: 4px;
    margin: 4px 2px;
}
.VpResults {
    display: flex;
    margin: 10px;
    flex-direction: column;
    align-items: flex-start;
}
.result {
    display: flex;
    margin-bottom: 10px;
    border: 1px solid;
    border-radius: 5px;

    .md-selection-control-group {
        display: contents;
        .md-subheading-1 {
            display: flex;
            align-items: center;
            margin: 10px;
        }
    }
}
.symptoms {
    margin: 10px 0px;
    border-radius: 5px;
    display: inline-block;
    width: 100%;
}
.essContent {
    padding: 5px;
    .innerContainer {
        min-height: 50vh;
    }
}
.continue_later_btn_container{
    display: flex;
    width: 50%;
    justify-content: flex-end;
    .continue_later_btn{
        width: auto;
        background-color: #4497f4;
        color: #ffffff;
    }
}
.fact-wrapper {
    height: 45vh;
    width: 100%;
    overflow: scroll;
    display: flex;
    align-items: center;
    justify-content: center;
    .fact-container{
        width: 100%;
        height: 100%;
        .md-grid {
            display: flex;
            justify-content: center;
        }
    }
}

.essSearch {
    background-color: #fff;
    border-radius: 10px;
    padding: 0 10px;
}
