.rettsLogo {
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
}

.courseName {
    text-align: center;
    width: 600px;
    position: absolute;
    top: 63%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 48px;
    line-height: 1;
}

.studentName {
    text-align: center;
    width: 600px;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 48px;
    line-height: 1;
}

.completeDate {
    position: absolute;
    top: 85%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 16px;
}

.bgImage {
    position: absolute;
    width: 100%;
    height: 100%;
}

@media print {
    .diplomaContent {
        display: block !important;
    }
}

@media all {
    .page-break {
        display: none;
    }
}

@media print {
    .bgImage {
        top: 0;
        margin: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
    .diplomaContent {
        display: block !important;
    }
}

@page {
    size: A4 portrait;
    margin: 1mm 1mm 1mm 1mm;
}
